import moment from "moment";
import startCase from "lodash.startcase";
import * as api from "@/constants/api";

export const dashboardStates = {
  namespaced: true,
  state: () => {
    return {
      theme: "light",
      registrationDataKey: -1,
      registrationData: {
        devicePixelRatio: 1,
        datasets: [
          {
            label: "Registrations",
            data: [],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(255, 99, 0)",
              "rgb(54, 90, 235)",
              "rgb(0, 205, 86)",
            ],
          },
        ],
        borderAlign: "inner",
        labels: [],
        totalData: 0,
        label: "Registrations",
      },
      userDataKey: -1,
      userData: {
        devicePixelRatio: 1,
        datasets: [
          {
            label: "Users",
            data: [],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(255, 99, 0)",
              "rgb(54, 90, 235)",
              "rgb(0, 180, 86)",
              "rgb(50, 205, 186)",
            ],
          },
        ],
        borderAlign: "inner",
        labels: [],
        totalData: 0,
        label: "Users",
      },
      faceLivenessDataKey: -1,
      faceLivenessData: {
        datasets: [
          {
            label: "Face Liveness Requests",
            data: [],
            backgroundColor: "rgba(0, 205, 86, 0.45)",
          },
        ],
      },
      faceMatchDataKey: -1,
      faceMatchData: {
        datasets: [
          {
            label: "Face Match Requests",
            data: [],
            backgroundColor: "rgba(255, 99, 132,  0.45)",
          },
        ],
      },
      ocrDataKey: -1,
      ocrData: {
        datasets: [
          {
            label: "OCR Requests",
            data: [],
            backgroundColor: "rgba(54, 90, 235, 0.5)",
          },
        ],
      },
      ocrDataDailyKey: -1,
      ocrDataDaily: {
        datasets: [
          {
            label: "Daily OCR Requests",
            data: [],
            backgroundColor: "rgba(24, 90, 135, 0.5)",
          },
        ],
      },
      ocrDataMonthlyKey: -1,
      ocrDataMonthly: {
        datasets: [
          {
            label: "Monthly OCR Requests",
            data: [],
            backgroundColor: "rgba(94, 90, 153, 0.5)",
          },
        ],
      },
      parseNidDataKey: -1,
      parseNidData: {
        datasets: [
          {
            label: "Parse NID Requests",
            data: [],
            backgroundColor: "rgba(234, 189, 28, 0.5)",
          },
        ],
      },
      porichoyRequestDataKey: -1,
      porichoyRequestData: {
        datasets: [
          {
            label: "Porichoy Requests",
            data: [],
            backgroundColor: "rgb(186, 181, 250, 0.5)",
          },
        ],
      },
      porichoyRequestDataDailyKey: -1,
      porichoyRequestDataDaily: {
        datasets: [
          {
            label: "Daily Porichoy Requests",
            data: [],
            backgroundColor: "rgb(86, 181, 250, 0.5)",
          },
        ],
      },
      porichoyRequestDataMonthlyKey: -1,
      porichoyRequestDataMonthly: {
        datasets: [
          {
            label: "Monthly Porichoy Requests",
            data: [],
            backgroundColor: "rgb(186, 81, 250, 0.5)",
          },
        ],
      },
      payableRequestDataKey: -1,
      payableRequestData: {
        datasets: [
          {
            label: "Payable Porichoy Requests",
            data: [],
            backgroundColor: "rgb(195, 141, 141, 0.5)",
          },
        ],
      },
      scraperRequestDataKey: -1,
      scraperRequestData: {
        datasets: [
          {
            label: "Scraper Requests",
            data: [],
            backgroundColor: "rgb(228, 134, 98, 0.5)",
          },
        ],
      },
    };
  },
  getters: {
    getTheme: (state) => state.theme,
    getRegistrationDataKey: (state) => state.registrationDataKey,
    getRegistrationData: (state) => state.registrationData,
    getUserDataKey: (state) => state.userDataKey,
    getUserData: (state) => state.userData,
    getFaceLivenessDataKey: (state) => state.faceLivenessDataKey,
    getFaceLivenessData: (state) => state.faceLivenessData,
    getFaceMatchDataKey: (state) => state.faceMatchDataKey,
    getFaceMatchData: (state) => state.faceMatchData,
    getOcrDataKey: (state) => state.ocrDataKey,
    getOcrData: (state) => state.ocrData,
    getOcrDataDailyKey: (state) => state.ocrDataDailyKey,
    getOcrDataDaily: (state) => state.ocrDataDaily,
    getOcrDataMonthlyKey: (state) => state.ocrDataMonthlyKey,
    getOcrDataMonthly: (state) => state.ocrDataMonthly,
    getParseNidDataKey: (state) => state.parseNidDataKey,
    getParseNidData: (state) => state.parseNidData,
    getPorichoyRequestDataKey: (state) => state.porichoyRequestDataKey,
    getPorichoyRequestData: (state) => state.porichoyRequestData,
    getPorichoyRequestDataDailyKey: (state) => state.porichoyRequestDataDailyKey,
    getPorichoyRequestDataDaily: (state) => state.porichoyRequestDataDaily,
    getPorichoyRequestDataMonthlyKey: (state) => state.porichoyRequestDataMonthlyKey,
    getPorichoyRequestDataMonthly: (state) => state.porichoyRequestDataMonthly,
    getPayableRequestDataKey: (state) => state.payableRequestDataKey,
    getPayableRequestData: (state) => state.payableRequestData,
    getScraperRequestDataKey: (state) => state.scraperRequestDataKey,
    getScraperRequestData: (state) => state.scraperRequestData,
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
    },
    setRegistrationData(state, { data, labels, totalData }) {
      if (state.registrationData.datasets[0].data !== data) {
        state.registrationData.datasets[0].data = data;
        state.registrationData.labels = labels;
        state.registrationData.totalData = totalData;
        state.registrationDataKey = totalData;
      }
    },
    setUsersData(state, { data, labels, totalData }) {
      if (state.userData.datasets[0].data !== data) {
        state.userData.datasets[0].data = data;
        state.userData.labels = labels;
        state.userData.totalData = totalData;
        state.userDataKey = totalData;
      }
    },
    setFaceLivenessData(state, { data, totalData }) {
      if (state.faceLivenessData.datasets[0].data !== data) {
        state.faceLivenessData.datasets[0].data = data;
        state.faceLivenessDataKey = totalData;
      }
    },
    setFaceMatchData(state, { data, totalData }) {
      if (state.faceMatchData.datasets[0].data !== data) {
        state.faceMatchData.datasets[0].data = data;
        state.faceMatchDataKey = totalData;
      }
    },
    setOcrData(state, { data, totalData }) {
      if (state.ocrData.datasets[0].data !== data) {
        state.ocrData.datasets[0].data = data;
        state.ocrDataKey = totalData;
      }
    },
    setOcrDataDaily(state, { data, totalData }) {
      if (state.ocrDataDaily.datasets[0].data !== data) {
        state.ocrDataDaily.datasets[0].data = data;
        state.ocrDataDailyKey = totalData;
      }
    },
    setOcrDataMonthly(state, { data, totalData }) {
      if (state.ocrDataMonthly.datasets[0].data !== data) {
        state.ocrDataMonthly.datasets[0].data = data;
        state.ocrDataMonthlyKey = totalData;
      }
    },
    setParseNidData(state, { data, totalData }) {
      if (state.parseNidData.datasets[0].data !== data) {
        state.parseNidData.datasets[0].data = data;
        state.parseNidDataKey = totalData;
      }
    },
    setPorichoyRequestData(state, { data, totalData }) {
      if (state.porichoyRequestData.datasets[0].data !== data) {
        state.porichoyRequestData.datasets[0].data = data;
        state.porichoyRequestDataKey = totalData;
      }
    },
    setPorichoyRequestDataDaily(state, { data, totalData }) {
      if (state.porichoyRequestDataDaily.datasets[0].data !== data) {
        state.porichoyRequestDataDaily.datasets[0].data = data;
        state.porichoyRequestDataDailyKey = totalData;
      }
    },
    setPorichoyRequestDataMonthly(state, { data, totalData }) {
      if (state.porichoyRequestDataMonthly.datasets[0].data !== data) {
        state.porichoyRequestDataMonthly.datasets[0].data = data;
        state.porichoyRequestDataMonthlyKey = totalData;
      }
    },
    setPayableRequestData(state, { data, totalData }) {
      if (state.payableRequestData.datasets[0].data !== data) {
        state.payableRequestData.datasets[0].data = data;
        state.payableRequestDataKey = totalData;
      }
    },
    setScraperRequestData(state, { data, totalData }) {
      if (state.scraperRequestData.datasets[0].data !== data) {
        state.scraperRequestData.datasets[0].data = data;
        state.scraperRequestDataKey = totalData;
      }
    },
  },
  actions: {
    async fetchUserCounts(ctx) {
      try {
        const response = await window.axios.get(api.USER_TOTAL_BY_ROLES);
        const levelTotal = response.data.data;
        let data = [];
        let labels = [];
        let totalData = 0;
        for (const el of Object.keys(levelTotal)) {
          totalData += levelTotal[el];
          data.push(levelTotal[el]);
          labels.push(el);
        }
        if (totalData === 0) {
          data = data.map(() => 1);
        }
        ctx.commit("setUsersData", { data, labels, totalData });
      } catch (err) {
        ctx.commit("setUsersData", { data: [], labels: [], totalData: 0 });
      }
    },
    async fetchRegistrationCounts(ctx) {
      try {
        const response = await window.axios.get(api.ONBOARDING_MFS_COUNT_REGISTRATIONS);
        const totalData = response.data.data.totalCount;
        const countByStatus = response.data.data.countByStatus;
        let data = [];
        let labels = [];
        for (const el of Object.keys(countByStatus)) {
          data.push(countByStatus[el] ? countByStatus[el] : 0);
          labels.push(startCase(el.toLowerCase()));
        }
        ctx.commit("setRegistrationData", { data, labels, totalData });
      } catch (err) {
        ctx.commit("setRegistrationData", { data: [], labels: [], totalData: 0 });
      }
    },
    async fetchLivenessRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.FACE_LIVENESS_REQUESTS_OVER_TIME, null, (data) =>
        ctx.commit("setFaceLivenessData", data)
      );
    },
    async fetchMatchRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.FACE_MATCH_REQUESTS_OVER_TIME, null, (data) =>
        ctx.commit("setFaceMatchData", data)
      );
    },
    async fetchOCRRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.OCR_REQUESTS_OVER_TIME, null, (data) =>
        ctx.commit("setOcrData", data)
      );
    },
    async fetchDailyOCRRequestCounts(ctx) {
      const today = new Date().setHours(0, 0, 0, 0);
      await fetchRequestOverTimeData(
        api.OCR_REQUESTS_OVER_TIME,
        {
          startTime: Math.floor(today / 1000),
        },
        (data) => ctx.commit("setOcrDataDaily", data)
      );
    },
    async fetchMonthlyOCRRequestCounts(ctx) {
      const today = new Date().setHours(0, 0, 0, 0);
      await fetchRequestOverTimeData(
        api.OCR_REQUESTS_OVER_TIME,
        {
          startTime: Math.floor(today / 1000 - 30 * 24 * 3600),
        },
        (data) => ctx.commit("setOcrDataMonthly", data)
      );
    },
    async fetchParseNidRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.PARSE_REQUESTS_OVER_TIME, null, (data) =>
        ctx.commit("setParseNidData", data)
      );
    },
    async fetchPorichoyRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.PORICHOY_REQUESTS_OVER_TIME, null, (data) =>
        ctx.commit("setPorichoyRequestData", data)
      );
    },
    async fetchDailyPorichoyRequestCounts(ctx) {
      const today = new Date().setHours(0, 0, 0, 0);
      await fetchRequestOverTimeData(
        api.PORICHOY_REQUESTS_OVER_TIME,
        {
          startTime: Math.floor(today / 1000),
        },
        (data) => ctx.commit("setPorichoyRequestDataDaily", data)
      );
    },
    async fetchMonthlyPorichoyRequestCounts(ctx) {
      const today = new Date().setHours(0, 0, 0, 0);
      await fetchRequestOverTimeData(
        api.PORICHOY_REQUESTS_OVER_TIME,
        {
          startTime: Math.floor(today / 1000 - 30 * 24 * 3600),
        },
        (data) => ctx.commit("setPorichoyRequestDataMonthly", data)
      );
    },
    async fetchPayableRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.PORICHOY_PAYABLE_OVER_TIME, null, (data) =>
        ctx.commit("setPayableRequestData", data)
      );
    },
    async fetchScraperRequestCounts(ctx) {
      await fetchRequestOverTimeData(api.NID_SCRAPER_REQUESTS_OVER_TIME, null, (data) =>
        ctx.commit("setScraperRequestData", data)
      );
    },
  },
};

async function fetchRequestOverTimeData(url, params, onDone) {
  try {
    const response = await window.axios.get(url, { params });
    const totalData = response.data.data.total;
    const data = response.data.data.data.map((e) => ({
      t: moment.unix(e.time),
      y: e.count,
    }));
    onDone({ data, totalData });
  } catch (err) {
    onDone({ data: [], totalData: 0 });
  }
}
